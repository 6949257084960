import Head from 'next/head'
import Footer from 'components/Footer'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { LayoutContextProvider } from 'context/LayoutContext'
import { useRef } from 'react'
import ErrorAlert from './ErrorAlert/ErrorAlert'

export default function Layout({
  globals,
  footer,
  siteUrl = 'https://www.blueland.com',
  canonical = '/',
  children,
  allowIndexing = true,
  ...rest
}) {
  const metaTitle = rest?.metaTitle ?? globals?.metaTitle
  const metaDescription = rest?.metaDescription ?? globals?.metaDescription
  const openGraphTitle = rest?.openGraphTitle ?? globals?.openGraphTitle ?? metaTitle
  const openGraphDescription =
    rest?.openGraphDescription ?? globals?.openGraphDescription ?? metaDescription
  const openGraphImage = rest?.openGraphImage ?? globals?.openGraphImage
  const twitterTitle = rest?.twitterTitle ?? globals?.twitterTitle ?? metaTitle
  const twitterDescription =
    rest?.twitterDescription ?? globals?.twitterDescription ?? metaDescription
  const twitterImage = rest?.twitterImage ?? globals?.twitterImage ?? openGraphImage

  const footerRef = useRef(null)

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href={`${siteUrl}${canonical}`} />

        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />

        <meta property="og:url" content={`${siteUrl}${canonical}`} />
        <meta property="og:title" content={openGraphTitle} />
        <meta property="og:site_name" content="Blueland" />
        <meta property="og:description" content={openGraphDescription} />
        {openGraphImage ? <meta property="og:image" content={openGraphImage.asset.url} /> : null}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`${siteUrl}${canonical}`} />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:site" content="@blueland" />
        <meta name="twitter:description" content={twitterDescription} />
        {twitterImage ? <meta name="twitter:image:src" content={twitterImage.asset.url} /> : null}
        {allowIndexing === false && <meta name="robots" content="noindex,nofollow" />}

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Corporation',
              name: 'Blueland',
              description:
                'Blueland creates everyday eco-friendly cleaning products to save you money and space, without any plastic waste.',
              logo: '/android-chrome-512x512.png',
              email: 'mailto:help@blueland.com',
              url: 'https://www.blueland.com/',
              sameAs: [
                'https://www.instagram.com/blueland/ ',
                'https://www.facebook.com/liveblueland/ ',
                'https://www.linkedin.com/company/liveblueland',
                'https://www.tiktok.com/@blueland ',
              ],
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'customer support',
                email: 'help@blueland.com',
              },
            }),
          }}
        />

        {/* Icons */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#003057" />
        <meta name="msapplication-TileColor" content="#003057" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <LayoutContextProvider footerRef={footerRef} globals={globals}>
        <div>
          <main id="maincontent">{children}</main>
          <ErrorBoundary>
            <Footer {...footer} ref={footerRef} />
          </ErrorBoundary>
        </div>
        <ErrorAlert />
      </LayoutContextProvider>
    </>
  )
}
