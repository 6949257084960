// context module function to provide layout context including footer ref
import React, { PropsWithChildren, createContext, useContext, useRef } from 'react'

type Context = {
  footerRef: React.MutableRefObject<HTMLElement | null>
  globals: any
} | null

const LayoutContext = createContext<Context>(null)

type LayoutContextProviderProps = PropsWithChildren<{
  footerRef: React.MutableRefObject<HTMLElement | null>
  globals: any
}>

export function LayoutContextProvider({
  children,
  footerRef,
  globals,
}: LayoutContextProviderProps) {
  return <LayoutContext.Provider value={{ footerRef, globals }}>{children}</LayoutContext.Provider>
}

export function useLayoutContext() {
  const context = useContext(LayoutContext)

  if (!context) {
    throw new Error('useLayoutContext must be used within a LayoutContextProvider')
  }

  return context
}
