import serializers from 'serializers/stylizedHeadline'
import BlockContent from '@sanity/block-content-to-react'
import Newsletter from 'components/Newsletter'
import Link from 'components/Link'
import useMedia from 'use-media'
import { screens } from 'lib/constants'
import Image from '../components/Image'

import { forwardRef, useRef } from 'react'
import { useRect } from '@reach/rect'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion'
import cx from 'classnames'
import * as analytics from 'lib/analytics'
import NextSanityImage from './NextSanityImage'
import YourPrivacyChoices from './YourPrivacyChoices'
import { useStore } from 'context/globalStore'
import { updateSubscriptionLink } from '../lib/abTestUtils'

const DISABLED_FOR_INT_LINKS = ['subscription']

function MenuLinkAccordion({ menus, countryCode, isGetSubscriptionPage }) {
  return (
    <Accordion collapsible={true} multiple={true} defaultIndex={0}>
      {menus.map((menu, i) => (
        <AccordionItem
          key={menu._key}
          className="border-t border-navy-blue-muted border-opacity-25"
        >
          <AnimatedAccordionButton>{menu.title}</AnimatedAccordionButton>
          <AnimatedAccordionPanel>
            <ul className="space-y-22">
              {menu.links?.filter(
                (link) => !(countryCode !== 'US' && DISABLED_FOR_INT_LINKS.some(item => link?.reference?.slug?.includes(item)))
              ).map((link, i) => {
                link = updateSubscriptionLink(link, isGetSubscriptionPage)
                return (
                link.title === 'Accessibility Tool' ? (
                  <li key={link._key}>
                    <button data-acsb-custom-trigger="true">
                      <Link
                        className="text-15 s:text-16 font-medium tracking-1 pt-3 rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-pattens-blue focus-visible:ring-offset-4 focus-visible:ring-offset-royal-blue group"
                        link={link}
                      >
                        <div className="relative inline-block">
                          {link.image ? (
                            <div className="flex items-center cursor-pointer">
                              <span className="relative h-15 w-15 mr-10">
                                <Image
                                  fill={true}
                                  fit="contain"
                                  placeholder={false}
                                  image={link.image}
                                />
                              </span>
                              <span>{link.title}</span>
                            </div>
                          ) : (
                            <span>{link.title}</span>
                          )}
                          <div className="absolute bottom-0 w-full h-1 bg-malibu-blue transform scale-x-0 origin-right group-hover:scale-x-100 group-hover:origin-left transition ease-in-out-cubic"></div>
                        </div>
                      </Link>
                    </button>
                  </li>
                ) : (
                  <li key={link._key}>
                    <Link
                      className="text-14 font-normal tracking-1 pt-3 rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-pattens-blue focus-visible:ring-offset-4 focus-visible:ring-offset-royal-blue group"
                      link={link}
                    >
                      <div className="relative inline-block">
                        {link.image ? (
                          <div className="flex items-center cursor-pointer">
                            <span className="relative h-15 w-15 mr-10">
                              <Image
                                fill={true}
                                fit="contain"
                                placeholder={false}
                                image={link.image}
                              />
                            </span>
                            <span>{link.title}</span>
                          </div>
                        ) : (
                          <span>{link.title}</span>
                        )}
                        <div className="absolute bottom-0 w-full h-1 bg-malibu-blue transform scale-x-0 origin-right group-hover:scale-x-100 group-hover:origin-left transition ease-in-out-cubic"></div>
                      </div>
                    </Link>
                  </li>
                )
              )})}
            </ul>
          </AnimatedAccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

const AnimatedAccordionButton = ({ children }) => {
  const { isExpanded } = useAccordionItemContext()
  return (
    <AccordionButton
      className="text-16 font-medium leading-100 py-22 w-full text-left flex justify-between pr-10 rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-royal-blue"
      onClick={() => analytics.trackPdpClick(children)}
    >
      <div>{children}</div>
      <div className="w-12 h-12 relative flex justify-center">
        <div
          className={cx('w-2 h-full bg-white rounded-pill transition ease-in-out-cubic', {
            'transform -translate-1/2 -rotate-90': isExpanded,
          })}
        ></div>
        <div className="w-full h-2 absolute-center bg-white rounded-pill"></div>
      </div>
    </AccordionButton>
  )
}

const AnimatedAccordionPanel = ({ children }) => {
  const { isExpanded } = useAccordionItemContext()
  const ref = useRef()
  const rect = useRect(ref)

  return (
    <AccordionPanel
      className="text-13 overflow-y-hidden transition-all ease-in-out-cubic"
      style={{ maxHeight: isExpanded ? rect?.height : 0 }}
      // We need to set hidden to false for the exit animations to work
      // but the panel should still be hidden from the accessibility tree
      // when the panel is closed. We'll use aria-hidden instead.
      hidden={false}
      aria-hidden={!isExpanded || undefined}
    >
      <div ref={ref} className="pb-22" inert={isExpanded ? null : ''}>
        {children}
      </div>
    </AccordionPanel>
  )
}

/* eslint-disable react/display-name */
const Footer = forwardRef(
  ({ menus, socialMedia, secondaryMenu, copyright, newsletter, cert, isGetSubscriptionPage }, footerRef) => {
    const isSmall = useMedia({ minWidth: screens.s })
    const secondaryMenuItems = secondaryMenu.map((link, i) => (
      <li key={i}>
        <Link
          className="text-13 l:text-14 pt-2 rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-pattens-blue focus-visible:ring-offset-4 focus-visible:ring-offset-royal-blue group"
          link={link}
        >
          <div className="relative inline-block">
            {link.title}
            <div className="absolute bottom-0 w-full h-1 bg-malibu-blue transform scale-x-0 origin-right group-hover:scale-x-100 group-hover:origin-left transition ease-in-out-cubic duration-750"></div>
          </div>
        </Link>
      </li>
    ))

    const { store } = useStore()
    const { countryCode } = store
    return (
      <footer className="relative z-3 bg-navy-blue text-white" ref={footerRef}>
        <div className="h-8 bg-gradient-to-r from-bright-blue via-blush-pink to-malibu-blue"></div>
        <div className="py-40 m:pt-40 l:pt-70 px-15 s:px-25 m:px-40 l:px-60 xl:px-80">
          <div className="l:flex l:mb-80 justify-between">
            {/* Newsletter */}
            <div className="mb-45 s:mb-60 m:mb-80 l:mb-0 l:w-2/5 w-full l:pr-80">
              <div className="w-full l:max-w-450 text-center l:text-left">
                <div className="mb-15">
                  <BlockContent
                    blocks={newsletter.headline}
                    serializers={serializers('h2', isSmall ? 26 : 24)}
                  />
                </div>
                <p className="mb-25 text-15 s:text-16">{newsletter.body}</p>
                <Newsletter {...newsletter} />
                <ul className="mb-15 space-x-47 s:space-x-76 l:space-x-40 flex mt-24 justify-center l:justify-start m:mt-40">
                  {socialMedia?.[0].links.map((scLink, i) => (
                    <li key={scLink._key}>
                      <Link
                        className="text-15 s:text-16 font-medium tracking-1 pt-3 rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-pattens-blue focus-visible:ring-offset-4 focus-visible:ring-offset-royal-blue group"
                        link={scLink}
                      >
                        <div className="relative inline-block">
                          {scLink.image ? (
                            <div className="flex items-center cursor-pointer">
                              <span className="relative h-20 w-20">
                                <Image
                                  fill={true}
                                  fit="contain"
                                  placeholder={false}
                                  image={scLink.image}
                                  alt=""
                                />
                              </span>
                            </div>
                          ) : (
                            <span>{scLink.title}</span>
                          )}
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Menus */}
            <nav className="mb-45 hidden l:block l:mb-0 l:w-3/5 w-full">
              <div className="s:w-full">
                <ul className="grid l:grid-cols-3 l:gap-30 s:flex s:justify-around space-y-50 s:space-y-0">
                  {menus.map((menu, i) => (
                    <li className="pr-5 l:pr-10 max-w-192 w-full" key={i}>
                      <h3 className="text-13 s:text-14 font-medium uppercase tracking-2 mb-30">
                        {menu.title}
                      </h3>
                      <ul className="space-y-16 mb-16">
                        {menu.links?.filter(
                          (link) => !(countryCode !== 'US' && DISABLED_FOR_INT_LINKS.some(item => link?.reference?.slug?.includes(item)))
                        ).map((link, j) => {
                          link = updateSubscriptionLink(link, isGetSubscriptionPage)
                          return link.title === 'Accessibility Tool' ? (
                            <li key={i+'_'+j}>
                              <button data-acsb-custom-trigger="true">
                                <Link
                                  className="text-15 s:text-16 font-medium tracking-1 pt-3 rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-pattens-blue focus-visible:ring-offset-4 focus-visible:ring-offset-royal-blue group"
                                  link={link}
                                >
                                  <div className="relative inline-block">
                                    {link.image ? (
                                      <div className="flex items-center cursor-pointer">
                                        <span className="relative h-15 w-15 mr-10">
                                          <Image
                                            fill={true}
                                            fit="contain"
                                            placeholder={false}
                                            image={link.image}
                                            alt=''
                                          />
                                        </span>
                                        <span>{link.title}</span>
                                      </div>
                                    ) : (
                                      <span>{link.title}</span>
                                    )}
                                    <div className="absolute bottom-0 w-full h-1 bg-malibu-blue transform scale-x-0 origin-right group-hover:scale-x-100 group-hover:origin-left transition ease-in-out-cubic"></div>
                                  </div>
                                </Link>
                              </button>
                            </li>
                          ) : (
                            <li key={i+'_'+j}>
                              <Link
                                className="text-15 s:text-16 font-medium tracking-1 pt-3 rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-pattens-blue focus-visible:ring-offset-4 focus-visible:ring-offset-royal-blue group"
                                link={link}
                              >
                                <div className="relative inline-block">
                                  {link.image ? (
                                    <div className="flex items-center cursor-pointer">
                                      <span className="relative h-15 w-15 mr-10">
                                        <Image
                                          fill={true}
                                          fit="contain"
                                          placeholder={false}
                                          image={link.image}
                                          alt=''
                                        />
                                      </span>
                                      <span>{link.title}</span>
                                    </div>
                                  ) : (
                                    <span>{link.title}</span>
                                  )}
                                  <div className="absolute bottom-0 w-full h-1 bg-malibu-blue transform scale-x-0 origin-right group-hover:scale-x-100 group-hover:origin-left transition ease-in-out-cubic"></div>
                                </div>
                              </Link>
                            </li>
                          )}
                        )}
                      </ul>
                    </li>
                  ))}

                  <li className="self-end s:hidden">
                    <ul className="block items-baseline space-y-15 mb-15">{secondaryMenuItems}</ul>
                    <ul className="block items-baseline space-y-15 mb-15">
                      <YourPrivacyChoices />
                    </ul>
                  </li>
                  <li className="self-start s:hidden">
                    <h3 className="text-13 s:text-14 font-medium uppercase tracking-2 mb-30">
                      {cert.title}
                    </h3>
                    <ul className="flex space-x-32 items-center">
                      {cert.certificates.map((item) => (
                        <li key={item._key}>
                          <img className="max-w-54" src={item.asset.url} alt={item.asset.altText} />
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>

            {/* Mobile Menu */}
            <div className="l:hidden">
              <MenuLinkAccordion menus={menus} countryCode={countryCode} isGetSubscriptionPage={isGetSubscriptionPage} />

              <ul className="flex space-x-32 justify-around items-center py-40 list-none border-t border-navy-blue-muted border-opacity-25">
                {cert.certificates.map((item) => (
                  <li key={item._key}>
                    <img className="max-w-54" src={item.asset.url} alt={item.asset.altText} />
                  </li>
                ))}
              </ul>

              <ul className="block items-baseline space-y-16 mb-16 list-none">
                {secondaryMenuItems}
              </ul>
              <ul className="block items-baseline space-y-16 mb-16 list-none">
                <YourPrivacyChoices />
              </ul>
            </div>
          </div>

          {/* Mobile Copyright */}
          <p className="text-13 l:hidden">
            {copyright.replace('{year}', new Date().getFullYear())}
          </p>

          {/* Desktop Copyright + Secondary Menu */}
          <div className="justify-between items-end hidden l:flex">
            <ul className="flex items-baseline space-x-20 m:space-x-20 l:space-x-30">
              <li className="mt-3">
                <p className="text-13 l:text-14">
                  {copyright.replace('{year}', new Date().getFullYear())}
                </p>
              </li>
              {secondaryMenuItems}
              <YourPrivacyChoices />
            </ul>
            <ul className="flex space-x-40 xl:space-x-88 items-center">
              {cert?.certificates?.map((item, i) => (
                <li key={i}>
                  <NextSanityImage
                    className="max-w-60"
                    image={item}
                    width={60}
                    height={91}
                    alt={item.asset.altText}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </footer>
    )
  }
)

export default Footer
