export default function stylizedHeadline(Tag = 'h2', size, wrap = true) {
  return {
    marks: {
      span: ({ children }) => (
        <span className="font-serif-modern italic" style={{ fontSize: '1.28em', lineHeight: '0px' }}>
          {children}
        </span>
      ),
    },
    types: {
      block: ({ children }) => (
        <Tag className={'stylized-headline leading-130 font-medium'} style={{ 
          fontSize: size, 
          whiteSpace: wrap === false ? 'nowrap' : 'none'
        }}>
          {children}
        </Tag>
      ),
    },
  }
}
