import { subscribe } from 'klaviyo-subscribe'
import cx from 'classnames'
import { useState, useRef } from 'react'
import useDelayedCallback from 'hooks/useDelayedCallback'
import wait from 'w2t'
import CheckmarkIcon from 'components/Icons/CheckmarkIcon'
import * as analytics from 'lib/analytics'
import { useCountryCode } from 'context/globalStore'
import { getKlaviyoListId } from 'lib/getKlaviyoListId'
import Link from './Link'

const States = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
}

export default function Newsletter({
  klaviyoListId,
  klaviyoInternationalListId,
  inputPlaceholder,
  signupConfirmationMessage,
  signupErrorMessage,
  inputType = 'email',
  validator = () => true,
  onSuccess = () => { },
}) {
  const { countryCode } = useCountryCode();
  const [status, setStatus] = useState(States.IDLE)
  const formRef = useRef()
  const delayedCallback = useDelayedCallback(() => {
    formRef.current.reset()
    setStatus(States.IDLE)
  }, 3000)

  function handleSubmit(ev) {
    ev.preventDefault()

    if (!validator(formRef.current.emailAddress.value)) {
      console.log('invalid email')
      setStatus(States.ERROR)
      return
    }

    setStatus(States.LOADING)
    wait(1000, [subscribe(getKlaviyoListId(countryCode, klaviyoListId ?? "", klaviyoInternationalListId ?? ""), formRef.current.emailAddress.value, {})])
      .then(() => {
        analytics.trackEmailCapture()
        setStatus(States.SUCCESS)
        delayedCallback()
        onSuccess()
      })
      .catch(() => {
        setStatus(States.ERROR)
        delayedCallback()
      })
  }

  const privacyPolicyLink = {
    reference: { _type: 'page', slug: 'privacy-policy' },
    _type: 'internalLink',
  }

  return (
    <>
      <div className="relative">
        <form ref={formRef} onSubmit={handleSubmit} className="text-16 s:text-18 rounded-pill">
          <label htmlFor="emailAddress" className="sr-only">
            Email Address
          </label>
          <input
            id="emailAddress"
            type={inputType}
            disabled={status === States.IDLE ? null : ''}
            className={cx(
              'w-full bg-white text-navy-blue font-medium py-13 l:py-15 pl-25 s:pl-28 rounded-pill placeholder-royal-blue placeholder-opacity-65 transition focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-royal-blue focus:ring-pattens-blue',
              { 'text-navy-blue-muted': status === States.LOADING }
            )}
            name="emailAddress"
            placeholder={inputPlaceholder}
            autoComplete="off"
            required
            onFocus={(_) => {
              if (status === States.SUCCESS || status === States.ERROR) setStatus(States.IDLE)
            }}
          />
          <button
            type="submit"
            disabled={status === States.IDLE ? null : ''}
            className="absolute z-1 inset-y-0 right-0 bg-bright-blue rounded-pill transition ease-out-quart duration-500 focus:outline-none group"
          >
            <span className="sr-only">Submit</span>
            <div className="relative w-full h-full flex items-center justify-center">
              <div className="relative z-1 py-15 px-20 s:px-32">
                <Arrow status={status} />
              </div>
              <div className="absolute z-1 inset-0 flex justify-center items-center">
                <Spinner status={status} />
              </div>
              <div className="absolute z-1 inset-0 flex justify-center items-center">
                <Checkmark status={status} color="#ffffff" />
              </div>
              <div className="absolute inset-0 rounded-pill border border-navy-blue bg-gradient-to-r transition-opacity opacity-0 group-hover:opacity-100 group-focus:opacity-100"></div>
            </div>
          </button>
        </form>
        <p
          className={cx(
            'absolute inset-0 w-full bg-white text-15 s:text-18 text-navy-blue font-medium py-13 l:py-15 pl-25 s:pl-28 rounded-pill transition ease-in-out-quart duration-500 pointer-events-none',
            { 'opacity-0': status !== States.SUCCESS }
          )}
        >
          {signupConfirmationMessage}
        </p>
        <p
          className={cx(
            'absolute inset-0 w-full bg-white text-15 s:text-18 text-navy-blue font-medium py-13 l:py-15 pl-25 s:pl-28 rounded-pill transition ease-in-out-quart duration-500 pointer-events-none',
            { 'opacity-0': status !== States.ERROR }
          )}
        >
          {signupErrorMessage}
        </p>
      </div>
      <p className="mt-24 text-13 s:text-14 s:align-middle mb-24 px-18 s:px-0">
        By signing up, you consent to our <Link link={privacyPolicyLink} className="inline-block underline">Privacy Policy</Link>. You may unsubscribe at any time.
      </p>
    </>
  )
}

function Arrow({ status }) {
  return (
    <div
      className={cx('transition ease-in-out-quart duration-500', {
        'opacity-0': status !== States.IDLE,
        'transform translate-x-20': status === States.LOADING,
        'transform -translate-x-20': status === States.SUCCESS || status === States.ERROR,
      })}
    >
      <svg
        width="33"
        height="15"
        viewBox="0 0 33 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7535 12.9324C24.436 13.2499 24.436 13.7646 24.7535 14.0821C25.071 14.3997 25.5858 14.3997 25.9033 14.0821L32.6569 7.32861L25.9033 0.575079C25.5858 0.257574 25.071 0.257574 24.7535 0.575079C24.436 0.892584 24.436 1.40736 24.7535 1.72487L30.3573 7.32861L24.7535 12.9324Z"
          fill="#ffffff"
        />
        <path
          d="M0 7.32843C0 6.87832 0.364888 6.51343 0.815 6.51343H31V8.14343H0.815001C0.364889 8.14343 0 7.77854 0 7.32843Z"
          fill="#ffffff"
        />
      </svg>
    </div>
  )
}

function Spinner({ status }) {
  return (
    <div
      className={cx('transition ease-in-out-quart duration-500', {
        'transform -translate-x-15': status === States.IDLE,
        'opacity-0': status !== States.LOADING,
        'transform translate-x-15': status === States.SUCCESS || status === States.ERROR,
      })}
    >
      <div className="animate-spin">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6518 12.4446C15.3794 11.3556 15.8262 10.1084 15.9585 8.81369C16.0043 8.36587 15.6351 8 15.1849 8C14.7348 8 14.3753 8.3668 14.3184 8.81333C13.919 11.9472 11.2424 14.3699 7.99989 14.3699C4.48183 14.3699 1.62989 11.5179 1.62989 7.99988C1.62989 4.75732 4.05267 2.08062 7.18673 1.6813C7.63317 1.62441 7.99991 1.26506 7.99995 0.815C7.99998 0.364897 7.63415 -0.00429951 7.18639 0.0414753C5.89167 0.173832 4.64438 0.620637 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346631 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446Z"
            fill="#ffffff"
          />
        </svg>
      </div>
    </div>
  )
}

function Checkmark({ status, color }) {
  return (
    <div
      className={cx('w-22 transition ease-in-out-quart duration-500 text-navy-blue', {
        'transform translate-x-15': status === States.IDLE,
        'transform -translate-x-15': status === States.LOADING,
        'opacity-0': status !== States.SUCCESS,
      })}
    >
      <CheckmarkIcon color={color} />
    </div>
  )
}
