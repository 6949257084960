import { PrivacyChoicesIcon } from '../components/Icons/privacyChoices'

type Props = {}

const YourPrivacyChoices = (props: Props) => {
  return (
    <li
      className="cursor-pointer w-max text-13 l:text-14 pt-2 rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-pattens-blue focus-visible:ring-offset-4 focus-visible:ring-offset-royal-blue group"
      onClick={() => {
        window && window?.OneTrust?.ToggleInfoDisplay()
      }}
    >
      <div className="flex items-center">
        <div className="relative">
          <span>Your Privacy Choices</span>
          <div className="absolute bottom-0 w-full h-1 bg-malibu-blue transform scale-x-0 origin-right group-hover:scale-x-100 group-hover:origin-left transition ease-in-out-cubic duration-750"></div>
        </div>
        <span className="w-35 ml-5">
          <PrivacyChoicesIcon />
        </span>
      </div>
    </li>
  )
}

export default YourPrivacyChoices
